import React, { Component, useContext, useRef, useEffect } from "react"
import { Tooltip as MateriaUiTooltip, makeStyles, MenuItem, Select } from "@material-ui/core"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap"

import { AccountsContext } from "./context"
import OrgDropdown from "./list"
import TotalUsersReport from "./controls/users"
import TotalProjectsReport from "./controls/projects"
import TotalBandwidthUsageGitReport from "./controls/bandwidth-usage-git"
import TotalBandwidthUsageArtifactoryReport from "./controls/bandwidth-usage-artifactory"
import TotalStorageUsage from "./controls/storage-usage"
import TotalMirrors from "./controls/git-mirrors"
import classnames from "classnames"
import _ from "lodash"
import moment from "moment"
import { FaRegFilePdf, FaRegFileExcel } from "react-icons/fa"

function AccountsIndex(props) {
  let contextData = useContext(AccountsContext)

  var [openModal, setopenModal] = React.useState(false)
  var [activeTab, setActiveTab] = React.useState("1")
  const useStyles = makeStyles(() => ({
    modal: {
      width: 500,
    },
  }))
  const classes = useStyles()
  // const user_report_component = useRef()
  // const bandwidth_report_component = useRef()

  // useEffect(() => {
  //   if (_.isNil(props.projectId)) return
  //   contextData.setProjectId(props.projectId)
  //   contextData.setParentProps(props.parentProps)
  // }, [props.projectId, props.parentProps])

  // useEffect(() => {
  //   if (_.isNil(contextData.editingItem)) return
  //   setopenModal(true)
  // }, [contextData.editingItem])

  // useEffect(() => {
  //   if (!openModal) contextData.setEditingItem(null)
  // }, [openModal])

  const exportToExcel = () => {
    var html = window["getOuterHtml"]("accounts-report-header")
    html += "<div>&nbsp;</div>"
    html += window["getOuterHtml"]("accounts-excel-users-data")
    html += "<div>&nbsp;</div>"
    html += window["getOuterHtml"]("accounts-excel-users-service-data")
    html += "<div>&nbsp;</div>"
    html += window["getOuterHtml"]("accounts-excel-projects-data")
    html += "<div>&nbsp;</div>"
    html += window["getOuterHtml"]("accounts-excel-bandwidth-data-git")
    html += "<div>&nbsp;</div>"
    html += window["getOuterHtml"]("accounts-excel-bandwidth-data-artifactory")
    // html += "<div>&nbsp;</div>"
    // html += window["getOuterHtml"]("accounts-excel-storage-data")
    window["exportTableToExcel"]("", html, "AccountBilling-excel")
  }

  const exportToPdf = () => {
    contextData.setPdfExportStarted(true)
    setTimeout(async () => {
      await window["generatePDF"]("AccountReportContent", "AccountBilling")
      setTimeout(() => {
        contextData.setPdfExportStarted(false)
      }, 1000)
    }, 1000)
  }

  return (
    <React.Fragment>
      <OrgDropdown />
      <hr />
      {contextData.hasRecords && (
        <>
          <div className="float-right">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={() => exportToPdf()}
            >
              {/* <strong>pdf</strong> */}
              <FaRegFilePdf />
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => exportToExcel()}
            >
              {/* <strong>excel</strong> */}
              <FaRegFileExcel />
            </button>
          </div>

          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </>
      )}

      <div id="AccountReportContent">
        {contextData.hasRecords && (
          <>
            <div id="accounts-report-header">
              {!(
                _.isNil(contextData.selectedParentOrganization) ||
                _.isNil(contextData.selectedParentOrganization.id)
              ) && (
                <>
                  <strong>Organization : </strong>
                  {contextData.selectedParentOrganization.name}
                </>
              )}
              {!(
                _.isNil(contextData.selectedOrganization) ||
                _.isNil(contextData.selectedOrganization.id)
              ) && (
                <>
                  &nbsp;(
                  {contextData.selectedOrganization.name})
                </>
              )}
              <br />
              <strong>Period : </strong>
              {moment(contextData.startDate).format("YYYY-MM-DD")}
              &nbsp;&nbsp;<strong>To:</strong>&nbsp;
              {moment(contextData.endDate).format("YYYY-MM-DD")}
              <hr />
            </div>
          </>
        )}
        <TotalUsersReport />
        <br />
        <TotalProjectsReport />
        <br />
        <TotalBandwidthUsageGitReport />
        <br />
        <TotalBandwidthUsageArtifactoryReport />
        {/* <br />
        <TotalStorageUsage />
        <br />
        <TotalMirrors /> */}
        {/* <br />
        <TotalUsersReport />
        <br />
        <TotalProjectsReport />
        <br />
        <TotalBandwidthUsageReport />
        <br />
        <TotalStorageUsage /> */}
      </div>
    </React.Fragment>
  )
}

export default AccountsIndex
