import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "./context"
import { fetch_delete, fetch_get, fetch_patch, fetch_post, fetch_put } from "../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import DatePicker from "react-datepicker"

function ProjectLinksList(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [parentOrganizations, setParentOrganizations] = useState([])
  var [organizations, setOrganizations] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadParentOrganizations = async () => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/parentOrganization/list`, accessToken)
          .then(response => {
            var porgs = [{ id: 0, name: "All" }, ...response.porgs]
            // var orgs = [{ id: 0, name: "All" }, ...response.orgs]
            // console.log(obs)
            setParentOrganizations(porgs)
            setOrganizations(response.orgs)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting project links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  const getOrganizations = () => {
    var selectedPorgid = getSelectedParentOraganization()
    let orgs = []
    if (selectedPorgid !== null) {
      if (selectedPorgid === 0) {
        orgs = organizations
      } else {
        orgs = organizations.filter(function (item) {
          return parseInt(selectedPorgid) === item.parent_org_id
        })
      }
    }
    return orgs
  }

  const hande_parentOrganization_change = e => {
    // console.log(parentOrganizations, e.target.value)
    let searchRes = parentOrganizations.filter(function (item) {
      return parseInt(e.target.value) === item.id
    })
    // console.log("res", searchRes)
    contextData.setSelectedParentOrganization(searchRes[0])
    contextData.setSelectedOrganization({ id: 0, name: "All" })
  }

  const handle_submit = () => {
    contextData.setSubmitCounter(n => n + 1)
  }

  const hande_organization_change = e => {
    // console.log(parentOrganizations, e.target.value)
    let searchRes = organizations.filter(function (item) {
      return parseInt(e.target.value) === item.id
    })
    if (searchRes.length === 0) {
      searchRes.push({ id: 0, name: "All" })
    }
    // console.log("res", searchRes)
    contextData.setSelectedOrganization(searchRes[0])
  }

  const getSelectedParentOraganization = () => {
    if (
      _.isNil(contextData.selectedParentOrganization) ||
      _.isNil(contextData.selectedParentOrganization.id)
    )
      return null
    return contextData.selectedParentOrganization.id
  }

  const getSelectedOraganization = () => {
    if (_.isNil(contextData.selectedOrganization) || _.isNil(contextData.selectedOrganization.id))
      return null
    return contextData.selectedOrganization.id
  }

  useEffect(() => {
    LoadParentOrganizations()
  }, [])

  return (
    <React.Fragment>
      <>
        {/* <div>
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={() => LoadParentOrganizations()}
          >
            <strong>Refresh</strong>
          </button>
        </div> */}
        <div>
          {loadStatus.loading && !loadStatus.error && (
            <>
              <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
            </>
          )}
          {!loadStatus.loading && !loadStatus.error && (
            <>
              {parentOrganizations.length === 0 && <div>Sorry ! No record found</div>}
              {parentOrganizations.length > 0 && (
                <>
                  <div className="border border-dark rounded">
                    <form className="ml-2">
                      <div className="form-row">
                        <div className="form-group  col-2">
                          <span className="">
                            <strong>Start Date:</strong>
                          </span>
                          <br />
                          <DatePicker
                            selected={contextData.startDate}
                            onChange={date => contextData.setStartDate(date)}
                            selectsStart
                            startDate={contextData.startDate}
                            endDate={contextData.endDate}
                            maxDate={contextData.endDate ? contextData.endDate : new Date()}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div className="form-group col-2">
                          <span className="">
                            <strong>End Date:</strong>
                          </span>
                          <br />
                          <DatePicker
                            selected={contextData.endDate}
                            onChange={date => contextData.setEndDate(date)}
                            selectsEnd
                            startDate={contextData.startDate}
                            endDate={contextData.endDate}
                            minDate={contextData.startDate}
                            maxDate={new Date()}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>

                        <div className="col-2">
                          <span className="">
                            <strong>Parent organization:</strong>
                          </span>
                          <br />
                          <select
                            className="form-control form-control-sm"
                            value={getSelectedParentOraganization()}
                            aria-label="Default select example"
                            name="copyRightHolderOption"
                            onChange={e => hande_parentOrganization_change(e)}
                          >
                            <option selected value="-1">
                              Select a Parent Organization
                            </option>
                            {parentOrganizations.map((p, index) => (
                              <option key={"porg" + index} value={p.id}>
                                {p.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {getOrganizations().length > 0 && (
                          <>
                            <div className="col-3">
                              <span className="">
                                <strong>Organization:</strong>
                              </span>
                              <br />
                              <select
                                className="form-control form-control-sm"
                                value={getSelectedOraganization()}
                                aria-label="Default select example"
                                name="copyRightHolderOption1"
                                onChange={e => hande_organization_change(e)}
                              >
                                <option selected value="0">
                                  All
                                </option>
                                {getOrganizations().map((p, index) => (
                                  <option key={"org" + index} value={p.id}>
                                    {p.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </>
                        )}
                        <div className="col-3">
                          <br />
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={() => handle_submit()}
                          >
                            <strong>submit</strong>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}
          {!loadStatus.loading && loadStatus.error && (
            <div className="alert alert-danger py-1" role="alert">
              {loadStatus.errorMessage}
            </div>
          )}
        </div>
      </>
    </React.Fragment>
  )
}

export default ProjectLinksList
