import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import BandwidthUsageArtifactoryData from "../data/bandwidth-usage-artifactory"
// import BandwidthUsageGitBarChart from "../charts/bandwidthusage-git-repo-count-bar-apex"

function TotalBandwidthUsage(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = { porgid: porgid, orgid: orgid, startDate, startDate, endDate: endDate }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/bandwidthusage/report`, accessToken)
        fetch_post(`admin/report/total/bandwidthusage`, postdata, accessToken)
          .then(response => {
            if (response.length > 0) contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting project links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log("from load users", contextData.selectedParentOrganization)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    // LoadTotalUsersReport()
  }, [contextData.submitCounter])

  function bytesToSize(bytes) {
    var sizes = ["B", "K", "MB", "GB", "TB", "P"]
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + " " + sizes[i]
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2)
      }
    }
    return bytes + " P"
  }

  function secondsToDhms(seconds) {
    seconds = Number(seconds)
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor((seconds % (3600 * 24)) / 3600)
    var m = Math.floor((seconds % 3600) / 60)
    var s = Math.floor(seconds % 60)
    // console.log(d, h, m, s)

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
    return dDisplay + hDisplay + mDisplay + sDisplay
  }

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-bandwidth-data">
              {/* <div className="float-left">
                <h4>Bandwidth Usage Report</h4>
              </div> */}
              {/* {!contextData.pdfExportStarted && (
                <>
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => LoadTotalUsersReport()}
                    >
                      <strong>Refresh</strong>
                    </button>

                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["exportTableToExcel"](
                          "accounts-excel-bandwidth-data",
                          "",
                          "AccountBilling-total-bandwidth-excel"
                        )
                      }
                    >
                      <strong>excel</strong>
                    </button>
                  </div>
                </>
              )} */}

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    <br />
                    <br />
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-4">
                          <BandwidthUsageArtifactoryData />
                        </div>
                        <div className="col-sm">{/* <BandwidthUsageGitBarChart /> */}</div>
                      </div>
                    </div>
                  </>
                )}
                {/* </>
                )} */}
                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default TotalBandwidthUsage
