import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import { FaRedoAlt, FaRegFileExcel } from "react-icons/fa"

function TotalProjects(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = { porgid: porgid, orgid: orgid, startDate, endDate: endDate }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/projects/report`, accessToken)
        fetch_post(`admin/report/total/projects`, postdata, accessToken)
          .then(response => {
            if (response.length > 0) contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log("from load users", contextData.selectedParentOrganization)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-projects-data">
              <div className="float-left">
                <h4>Overview</h4>
              </div>
              {!contextData.pdfExportStarted && (
                <>
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-sm"
                      onClick={() => LoadTotalUsersReport()}
                    >
                      {/* <strong>Refresh</strong> */}
                      <FaRedoAlt />
                    </button>
                    {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"](
                          "accounts-projects-data",
                          "AccountBilling-total-projects"
                        )
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={() =>
                        window["exportTableToExcel"](
                          "accounts-excel-projects-data",
                          "",
                          "AccountBilling-total-projects-excel"
                        )
                      }
                    >
                      {/* <strong>excel</strong> */}
                      <FaRegFileExcel />
                    </button>
                  </div>
                </>
              )}

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    {dbRecords.length === 0 && <div>Sorry ! No projects report found</div>}
                    {dbRecords.length > 0 && (
                      <>
                        <div id="accounts-excel-projects-data">
                          <span style={{ display: "none" }}>projects Report</span>
                          <table className="table table-sm">
                            {/* <thead>
                              <tr>
                                <th scope="col">Item</th>
                                <th scope="col">
                                  {" "}
                                  <span className="float-right">Count</span>
                                </th>
                              </tr>
                            </thead> */}
                            <tbody>
                              {/* {dbRecords.map((p, index) => (
                                <tr>
                                  <td>{p.item}</td>
                                  <td>
                                    <span className="float-right">{p.cnt}</span>
                                  </td>
                                </tr>
                              ))} */}
                              {/* <tr>
                                <td>Total projects to date</td>
                                <td>
                                  <span className="float-right">{dbRecords[0].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                  &nbsp;&nbsp;Active
                                </td>
                                <td>
                                  <span className="float-right">{dbRecords[1].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                  &nbsp;&nbsp;Inactive
                                </td>
                                <td>
                                  <span className="float-right">{dbRecords[2].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                  &nbsp;&nbsp;Archived
                                </td>
                                <td>
                                  <span className="float-right">{dbRecords[3].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                  &nbsp;&nbsp;Public
                                </td>
                                <td>
                                  <span className="float-right">{dbRecords[4].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                  &nbsp;&nbsp;Private
                                </td>
                                <td>
                                  <span className="float-right">{dbRecords[5].cnt}</span>
                                </td>
                              </tr> */}
                              <tr>
                                <td>New projects this period</td>
                                <td>
                                  <span className="float-right">{dbRecords[6].cnt}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default TotalProjects
