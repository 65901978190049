import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import echarts from "echarts"
import { css } from "glamor"
import { generateRandomInteger } from "../../../../utils/common"
import { AccountsContext } from "../context"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import Chart from "react-apexcharts"
import { FaRedoAlt } from "react-icons/fa"

export default function EchartsUploadsDownloadsBar(props) {
  var [chartElementId, setChartElementId] = useState(null)
  var [graphType, setGraphType] = useState("YYYY-WW")
  var graphTypeOptions = [
    { name: "Week", code: "YYYY-WW" },
    { name: "Month", code: "YYYY Mon" },
  ]
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [chartSettings, setchartSettings] = useState({
    options: {},
    series: {},
  })

  // console.log(props, 'props')

  const drawBar = () => {
    var resSeries = []

    resSeries.push({
      name: "Upload",
      data: [],
    })
    resSeries.push({
      name: "Download",
      data: [],
    })
    dbRecords.forEach(record => {
      // var item = [`${record.wk}`, record.activecount, record.inactivecount]
      resSeries[0].data.push((record.upload_bandwidth / 1024 / 1024 / 1024).toFixed(2))
      resSeries[1].data.push((record.download_bandwidth / 1024 / 1024 / 1024).toFixed(2))
    })

    var resLabel = []

    // console.log(dbRecords_git_repocount.length)
    // console.log(dbRecords_git_repocount)
    dbRecords.forEach(record => {
      resLabel.push(record.wk)
    })
    // console.log(res)

    const defaultColor = ["green", "orange"]

    var cs = {
      colors: defaultColor,
      series: resSeries,
      options: {
        title: {
          text: "Gitlab Bandwidth Usage",
        },
        chart: {
          type: "bar",
          // height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: resLabel,
        },
        yaxis: {
          title: {
            text: "Size in GB",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "GB"
            },
          },
        },
      },
    }

    setchartSettings(cs)
  }

  const LoadData = async () => {
    // return
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = {
      porgid: porgid,
      orgid: orgid,
      startDate,
      startDate,
      endDate: endDate,
      graphType: graphType,
    }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/report/bandwidthusage/git/upload/download/graph`, postdata, accessToken)
          .then(response => {
            // if (response.length > 0)
            console.log(response)
            contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadData()
  }, [contextData.submitCounter])

  // useEffect(() => {
  //   if (chartElementId === null) return
  //   drawBar()
  // }, [chartElementId])

  useEffect(() => {
    // createElementIdForChart()
    drawBar()
  }, [dbRecords])

  useEffect(() => {
    LoadData()
  }, [graphType])

  return (
    <div id="accounts-total-users-graph-data">
      <div className="float-left">{/* <h5>Accounts</h5> */}</div>

      {!contextData.pdfExportStarted && (
        <>
          <div className="float-right">
            <select
              className="form-control-sm"
              value={graphType}
              aria-label="Default select example"
              name="claimContentOnCodelinaroOption"
              onChange={e => setGraphType(e.target.value)}
            >
              {graphTypeOptions.map((p, index) => (
                <option value={p.code}>{p.name}</option>
              ))}
            </select>
            &nbsp;
            <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => LoadData()}
            >
              {/* <strong>Refresh</strong> */}
              <FaRedoAlt />
            </button>
          </div>
        </>
      )}
      <br />
      <br />
      {dbRecords.length > 0 && (
        <Chart
          options={chartSettings.options}
          series={chartSettings.series}
          type="bar"
          width="100%"
          height="300px"
        />
      )}
    </div>
  )
}
