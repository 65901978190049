import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import echarts from "echarts"
import { css } from "glamor"
import { generateRandomInteger } from "../../../../utils/common"
import { AccountsContext } from "../context"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import Chart from "react-apexcharts"
import { FaRedoAlt } from "react-icons/fa"

export default function EchartsHozBar(props) {
  var [chartElementId, setChartElementId] = useState(null)
  var [graphType, setGraphType] = useState("YYYY-WW")
  var graphTypeOptions = [
    { name: "Week", code: "YYYY-WW" },
    { name: "Month", code: "YYYY Mon" },
  ]
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords_git_repocount, setdbRecords_git_repocount] = useState([])
  var [dbRecords_git_repoFetchCommitcount, setdbRecords_git_repoFetchCommitcount] = useState([])
  var [dbRecords_git_mirrorcount, setdbRecords_git_mirrorcount] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [chartSettings_git_fetchCommitCount, setchartSettings_git_fetchCommitCount] = useState({
    options: {},
    series: {},
  })
  var [chartSettings_git_mirrorCount, setchartSettings_git_mirrorCount] = useState({
    options: {},
    series: {},
  })
  var [chartSettings_git_repoCount, setchartSettings_git_repoCount] = useState({
    options: {},
    series: {},
  })

  // console.log(props, 'props')

  const drawBar_git_repoCount = () => {
    var resSeries = []

    resSeries.push({
      name: "Repo Count",
      data: [],
    })
    dbRecords_git_repocount.forEach(record => {
      var item = [`${record.wk}`, record.activecount, record.inactivecount]
      resSeries[0].data.push(record.gitcount)
    })

    var resLabel = []

    // console.log(dbRecords_git_repocount.length)
    // console.log(dbRecords_git_repocount)
    dbRecords_git_repocount.forEach(record => {
      resLabel.push(record.wk)
    })
    // console.log(res)

    const defaultColor = ["green", "orange"]

    var cs = {
      colors: defaultColor,
      series: resSeries,
      options: {
        chart: {
          type: "bar",
          // height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: resLabel,
        },
        yaxis: {
          title: {
            text: "Repo Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
    }

    setchartSettings_git_repoCount(cs)
  }

  const drawBar_git_mirrorCount = () => {
    var resSeries = []

    resSeries.push({
      name: "Mirror Count",
      data: [],
    })
    dbRecords_git_mirrorcount.forEach(record => {
      var item = [`${record.wk}`, record.activecount, record.inactivecount]
      resSeries[0].data.push(record.mirrorcount)
    })

    var resLabel = []

    // console.log(dbRecords_git_repocount.length)
    // console.log(dbRecords_git_repocount)
    dbRecords_git_mirrorcount.forEach(record => {
      resLabel.push(record.wk)
    })
    // console.log(res)

    const defaultColor = ["green", "orange"]

    var cs = {
      colors: defaultColor,
      series: resSeries,
      options: {
        chart: {
          type: "bar",
          // height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: resLabel,
        },
        yaxis: {
          title: {
            text: "Mirror Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
    }

    setchartSettings_git_mirrorCount(cs)
  }

  const drawBar_git_fetchCommitCount = () => {
    var resSeries = []

    resSeries.push({
      name: "Fetch",
      data: [],
    })
    resSeries.push({
      name: "Commit",
      data: [],
    })
    dbRecords_git_repoFetchCommitcount.forEach(record => {
      var item = [`${record.wk}`, record.activecount, record.inactivecount]
      resSeries[0].data.push(record.fetchcount)
      resSeries[1].data.push(record.commitcount)
    })

    var resLabel = []

    // console.log(dbRecords_git_repocount.length)
    // console.log(dbRecords_git_repocount)
    dbRecords_git_repoFetchCommitcount.forEach(record => {
      resLabel.push(record.wk)
    })
    // console.log(res)

    const defaultColor = ["green", "orange"]

    var cs = {
      colors: defaultColor,
      series: resSeries,
      options: {
        chart: {
          type: "bar",
          // height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: resLabel,
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
    }

    setchartSettings_git_fetchCommitCount(cs)
  }

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = {
      porgid: porgid,
      orgid: orgid,
      startDate,
      startDate,
      endDate: endDate,
      graphType: graphType,
    }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/report/bandwidthusage/git/repocount/graph`, postdata, accessToken)
          .then(response => {
            // if (response.length > 0)
            console.log(response)
            contextData.setHasRecords(true)
            setdbRecords_git_repocount(response.gitrepo)
            setdbRecords_git_repoFetchCommitcount(response.gitfetchcommit)
            setdbRecords_git_mirrorcount(response.gitmirror)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords_git_repocount([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  // useEffect(() => {
  //   if (chartElementId === null) return
  //   drawBar_git_repoCount()
  // }, [chartElementId])

  useEffect(() => {
    // createElementIdForChart()
    drawBar_git_repoCount()
  }, [dbRecords_git_repocount])
  useEffect(() => {
    // createElementIdForChart()
    drawBar_git_mirrorCount()
  }, [dbRecords_git_mirrorcount])

  useEffect(() => {
    // createElementIdForChart()
    drawBar_git_fetchCommitCount()
  }, [dbRecords_git_repoFetchCommitcount])

  useEffect(() => {
    LoadTotalUsersReport()
  }, [graphType])

  return (
    <div id="accounts-total-users-graph-data">
      <div className="float-left">{/* <h5>Accounts</h5> */}</div>

      {!contextData.pdfExportStarted && (
        <>
          <div className="float-right">
            <select
              className="form-control-sm"
              value={graphType}
              aria-label="Default select example"
              name="claimContentOnCodelinaroOption"
              onChange={e => setGraphType(e.target.value)}
            >
              {graphTypeOptions.map((p, index) => (
                <option value={p.code}>{p.name}</option>
              ))}
            </select>
            &nbsp;
            <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => LoadTotalUsersReport()}
            >
              {/* <strong>Refresh</strong> */}
              <FaRedoAlt />
            </button>
            {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"]("accounts-total-users-data", "AccountBilling-users")
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
            {/* &nbsp;
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={() =>
                window["exportTableToExcel"](
                  "accounts-excel-users-data",
                  "",
                  "AccountBilling-users-excel"
                )
              }
            >
              <strong>excel</strong>
            </button> */}
          </div>
        </>
      )}
      <br />
      <br />
      {/* <div className="row" {...css(styles.borderStyle)} id={chartElementId}></div> */}
      {dbRecords_git_repocount.length > 0 && (
        <Chart
          options={chartSettings_git_repoCount.options}
          series={chartSettings_git_repoCount.series}
          type="bar"
          width="100%"
          height="300px"
        />
      )}

      {dbRecords_git_repoFetchCommitcount.length > 0 && (
        <Chart
          options={chartSettings_git_fetchCommitCount.options}
          series={chartSettings_git_fetchCommitCount.series}
          type="bar"
          width="100%"
          height="300px"
        />
      )}

      {dbRecords_git_mirrorcount.length > 0 && (
        <Chart
          options={chartSettings_git_mirrorCount.options}
          series={chartSettings_git_mirrorCount.series}
          type="bar"
          width="100%"
          height="300px"
        />
      )}
    </div>
  )
}

const styles = {
  borderStyle: {
    width: "100%",
    height: "300px",
  },
}
