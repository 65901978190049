import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import UserBarChart from "../charts/users-chart-bar-apex"
import UserData from "../data/users"
import UserServiceEntitlementData from "../data/users-service-entitlement"
import UserServiceEntitlementBarChart from "../charts/users-service_entitlement-chart-bar-apex"

function TotalUsers(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = { porgid: porgid, orgid: orgid, startDate, startDate, endDate: endDate }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/report/total/users`, postdata, accessToken)
          .then(response => {
            if (response.length > 0) contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting project links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    // LoadTotalUsersReport()
  }, [contextData.submitCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-users-data">
              <div className="float-left">
                <h3>Users</h3>
              </div>

              {/* {!contextData.pdfExportStarted && (
                <>
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => LoadTotalUsersReport()}
                    >
                      <strong>Refresh</strong>
                    </button> */}
              {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"]("accounts-total-users-data", "AccountBilling-users")
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
              {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["exportTableToExcel"](
                          "accounts-excel-users-data",
                          "",
                          "AccountBilling-users-excel"
                        )
                      }
                    >
                      <strong>excel</strong>
                    </button>
                  </div>
                </>
              )} */}

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}

                <br />
                <br />

                <div className="container">
                  <div className="row">
                    <div className="col-sm-4">
                      <UserData />
                    </div>
                    <div className="col-sm">
                      <UserBarChart />
                    </div>
                  </div>
                </div>
                <div id="accounts-excel-users-data">
                  <div style={{ display: "none" }}>Users</div>
                  <div style={{ display: "none" }}>Accounts</div>

                  <br />
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4">
                        <UserServiceEntitlementData />
                      </div>
                      <div className="col-sm">
                        <UserServiceEntitlementBarChart />
                      </div>
                    </div>
                  </div>
                </div>

                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default TotalUsers
