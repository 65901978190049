import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import echarts from "echarts"
import { css } from "glamor"
import { generateRandomInteger } from "../../../../utils/common"
import { AccountsContext } from "../context"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import Chart from "react-apexcharts"
import { FaRedoAlt, FaRegFileExcel } from "react-icons/fa"

export default function EchartsPercentageTabular(props) {
  var [graphType, setGraphType] = useState("YYYY Mon")
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadData = async () => {
    // return
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = {
      porgid: porgid,
      orgid: orgid,
      startDate,
      startDate,
      endDate: endDate,
      graphType: graphType,
    }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/report/bandwidthusage/git/percentage/graph`, postdata, accessToken)
          .then(response => {
            // if (response.length > 0)
            console.log(response)
            contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords([])
      return
    }

    LoadData()
  }, [contextData.submitCounter])

  useEffect(() => {
    LoadData()
  }, [graphType])

  return (
    <div id="accounts-total-users-graph-data">
      <div className="float-left">
        <strong>Gitlab Bandwidth Usage Percentage</strong>
      </div>

      {!contextData.pdfExportStarted && (
        <>
          <div className="float-right">
            <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => LoadData()}
            >
              <FaRedoAlt />
            </button>
            {dbRecords.length > 0 && (
              <>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  onClick={() =>
                    window["exportTableToExcel"](
                      "accounts-excel-bandwidth-usage-percentage-data-git",
                      "",
                      "AccountBilling-Git-bandwidth-excel"
                    )
                  }
                >
                  <FaRegFileExcel />
                </button>
              </>
            )}
          </div>
        </>
      )}
      <br />
      <br />
      {loadStatus.loading && !loadStatus.error && (
        <>
          <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
        </>
      )}
      {!loadStatus.loading && !loadStatus.error && (
        <>
          {dbRecords.length === 0 && (
            <>
              <div>Sorry ! No records found</div>
            </>
          )}
          {dbRecords.length > 0 && (
            <>
              <div
                id="accounts-excel-bandwidth-usage-percentage-data-git"
                style={{ height: "400px", overflow: "scroll" }}
              >
                <table className="table table-sm">
                  <thead style={{ position: "sticky", top: "0" }}>
                    <tr>
                      <td>Month</td>
                      <td>Organization</td>
                      <td>Usage</td>
                    </tr>
                  </thead>
                  <tbody>
                    {dbRecords.map(item => (
                      <>
                        <tr>
                          <td>{item.wk}</td>
                          <td>{item.orgname}</td>
                          <td>{item.upload_bandwidth.toFixed(6)}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
