import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import echarts from "echarts"
import { css } from "glamor"
import { generateRandomInteger } from "../../../../utils/common"
import { AccountsContext } from "../context"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import Chart from "react-apexcharts"
import { FaRedoAlt } from "react-icons/fa"

export default function EchartsHozBar(props) {
  var [chartElementId, setChartElementId] = useState(null)
  var [graphType, setGraphType] = useState("YYYY-WW")
  var graphTypeOptions = [
    { name: "Week", code: "YYYY-WW" },
    { name: "Month", code: "YYYY Mon" },
  ]
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [chartSettings, setChartSettings] = useState({ options: {}, series: {} })

  const createElementIdForChart = () => {
    var id1 = generateRandomInteger(10000)
    var id2 = generateRandomInteger(10000)
    var newId = `${id1}${id2}`
    setChartElementId(newId)
  }
  // console.log(props, 'props')

  const getDataSource_for_graph = () => {
    var res = []

    res.push({
      name: "Git",
      data: [],
    })

    res.push({
      name: "Artifactory",
      data: [],
    })
    res.push({
      name: "Wiki",
      data: [],
    })
    res.push({
      name: "Email",
      data: [],
    })
    res.push({
      name: "Support",
      data: [],
    })
    res.push({
      name: "IRC",
      data: [],
    })

    console.log(dbRecords.length)
    console.log(dbRecords)
    dbRecords.forEach(record => {
      var item = [`${record.wk}`, record.activecount, record.inactivecount]
      // console.log(item)
      res[0].data.push(record.gitcount)
      res[1].data.push(record.artifactorycount)
      res[2].data.push(record.wikicount)
      res[3].data.push(record.emailcount)
      res[4].data.push(record.supportcount)
      res[5].data.push(record.irccount)
      // for (let key in record) {
      //   console.log(`${key}: ${mobile[key]}`)
      // }
    })
    // console.log(res)
    return res
  }

  const get_graph_label = () => {
    var res = []

    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.forEach(record => {
      res.push(record.wk)
    })
    // console.log(res)
    return res
  }

  const drawBar = () => {
    var dd = getDataSource_for_graph()
    // const defaultColor = [
    //   "#A4C950",
    //   "#545FA9",
    //   "#6c757d",
    //   "#AE67A6",
    //   "#C2C319",
    //   "#349292",
    //   "#CA9657",
    //   "#F18F59",
    //   "#638FEA",
    //   "#B99C2C",
    // ]
    const defaultColor = ["green", "orange"]

    var cs = {
      series: getDataSource_for_graph(),
      options: {
        chart: {
          type: "bar",
          // height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: get_graph_label(),
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
    }

    setChartSettings(cs)
  }

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = {
      porgid: porgid,
      orgid: orgid,
      startDate,
      startDate,
      endDate: endDate,
      graphType: graphType,
    }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/report/total/users/services/graph`, postdata, accessToken)
          .then(response => {
            if (response.length > 0) contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  useEffect(() => {
    if (chartElementId === null) return
    drawBar()
  }, [chartElementId])

  useEffect(() => {
    createElementIdForChart()
  }, [dbRecords])

  useEffect(() => {
    LoadTotalUsersReport()
  }, [graphType])

  return (
    <div id="accounts-total-users-graph-data">
      <div className="float-left">{/* <h5>Accounts</h5> */}</div>

      {!contextData.pdfExportStarted && (
        <>
          <div className="float-right">
            <select
              className="form-control-sm"
              value={graphType}
              aria-label="Default select example"
              name="claimContentOnCodelinaroOption"
              onChange={e => setGraphType(e.target.value)}
            >
              {graphTypeOptions.map((p, index) => (
                <option value={p.code}>{p.name}</option>
              ))}
            </select>
            &nbsp;
            <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => LoadTotalUsersReport()}
            >
              {/* <strong>Refresh</strong> */}
              <FaRedoAlt />
            </button>
            {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"]("accounts-total-users-data", "AccountBilling-users")
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
            {/* &nbsp;
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={() =>
                window["exportTableToExcel"](
                  "accounts-excel-users-data",
                  "",
                  "AccountBilling-users-excel"
                )
              }
            >
              <strong>excel</strong>
            </button> */}
          </div>
        </>
      )}
      <br />
      <br />
      {/* <div className="row" {...css(styles.borderStyle)} id={chartElementId}></div> */}
      {dbRecords.length > 0 && (
        <Chart
          options={chartSettings.options}
          series={chartSettings.series}
          type="bar"
          width="100%"
          height="300px"
        />
      )}
    </div>
  )
}

const styles = {
  borderStyle: {
    width: "100%",
    height: "300px",
  },
}
